<template>
  <div class="meterTokens">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <meter-tokens-table
      :meter-token-url="meterTokenUrl"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  mdiShapeSquarePlus,
  mdiApplicationCog,
} from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import MeterTokensTable from '@/components/tables/MeterTokensTable.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    'meter-tokens-table': MeterTokensTable,
  },
  mixins: [hasPermission],
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Meter Tokens',
          disabled: true,
          to: { name: 'meter-tokens' },
        },
      ],
      icons: {
        mdiShapeSquarePlus,
        mdiApplicationCog,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    meterTokenUrl() {
      return `meter-tokens?station_id=${this.selectedStation}`
    },
  },
}
</script>
